import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const LotPositionReport = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const pdfTitles = [
    'Customer',
    'Sort',
    'Bale',
    'Date',
    'Pk Mts',
    'Snd Mts',
    'Shrt Len',
    'Sec Mts',
    'Fents Mts',
    'Rags Mts',
    'Oth Mts',
    'Fin Wid',
    'Prc Col',
    'Blend',
    'Nt Wt',
    'Gr Wt',
    'Pieces',
    'POC No.',
    'Lt. No.',
  ];
  const [pdfContent, setPdfContent] = useState([[]]);
  const [pdfData, setPdfData] = useState({});

  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
    getPOC('');
    getJobCard('');
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const localeNumGetter = (num: any) => {
    num = +num;
    return num.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        jobCard,
        poc,
      },
    };
    let validate = requireCheck(postData);

    if (!validate) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    let pocStr = poc.toString();
    let jbCrdStr = jobCard.toString();
    showLoader();
    axios
      .get(
        baseUri() +
          'grey-receipt-advice/lot-position-report?users=' +
          cust +
          '&customersSort=' +
          sort +
          '&jobCard=' +
          jbCrdStr +
          '&poc=' +
          pocStr,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
        console.log(error);
      });
  };

  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);
  const [poc, setPOC] = useState([]);
  const [defaultPOC, setDefaultPOC] = useState([{ label: 'All', value: '' }]);
  const [pocOptions, setPOCOptions] = useState([]);
  const [jobCard, setJobCard] = useState([]);
  const [defaultJobCard, setDefaultJobCard] = useState([{ label: 'All', value: '' }]);
  const [jobCardOptions, setJobCardOptions] = useState([]);

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    let pocFilter = [];
    let jobCardFilter = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
        pocFilter.push(`filters[customer][id][$in][${index}]=${item.value}`);
        jobCardFilter.push(`filters[processOrder][customer][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setJobCard([]);
    setPOC([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    setDefaultJobCard([{ label: 'All', value: '' }]);
    setSortOptions([]);
    setPOCOptions([]);
    setJobCardOptions([]);
    getSorts(fliterStr.join('&'));
    getPOC(pocFilter.join('&'));
    getJobCard(jobCardFilter.join('&'));
  };
  const handlePOCChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[processOrder][id][$in][${index}]=${item.value}`);
      }
    });
    setPOC(selected);
    setDefaultPOC(event);
    pullDownReset('poc');
    setJobCard([]);
    setDefaultJobCard([{ label: 'All', value: '' }]);
    setJobCardOptions([]);
    getJobCard(fliterStr.join('&'));
  };
  const handleJobCardChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setJobCard(selected);
    setDefaultJobCard(event);
    pullDownReset('jobCard');
    setPOC([]);
    setDefaultPOC([{ label: 'All', value: '' }]);
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    let pocFilter = [];
    let jobCardFilter = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        pocFilter.push(`filters[customersSort][id][$in][${index}]=${item.value}`);
        jobCardFilter.push(`filters[processOrder][customersSort][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
    setJobCard([]);
    setPOC([]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    setDefaultJobCard([{ label: 'All', value: '' }]);
    setPOCOptions([]);
    setJobCardOptions([]);
    getPOC(pocFilter.join('&'));
    getJobCard(jobCardFilter.join('&'));
  };

  const clearFormData = () => {
    getSorts('');
    getJobCard('');
    getPOC('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setJobCard([]);
    setDefaultJobCard([{ label: 'All', value: '' }]);
    setPOC([]);
    setDefaultPOC([{ label: 'All', value: '' }]);
    resetRequired();
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getPOC = (query) => {
    showLoader();
    axios
      .get(`${baseUri()}process-orders?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.id, value: item.id });
        });
        setPOCOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getJobCard = (query) => {
    showLoader();
    axios
      .get(`${baseUri()}job-cards?${query}&fields[0]=idLotNum`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: `${item.id}-${item.attributes.idLotNum}`, value: item.id });
        });
        setJobCardOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Customer',
      selector: (row) => `${row.Customer_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.Customer_Sort}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Lot Num',
      selector: (row) => `${row.LOT_NUM}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'MU ID',
      selector: (row) => `${dateFormat(row.MU_ID)}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'MC Code',
      selector: (row) => row.MC_Code,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Name',
      selector: (row) => row.Machine_Name,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Grp Name',
      selector: (row) => row.GrpName,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Start Time',
      selector: (row) => moment(row.Start_Time).format('DD/MM/YY h:mm a'),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'End Time',
      selector: (row) => moment(row.end_time).format('DD/MM/YY h:mm a'),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Packing',
      selector: (row) => row.Packing,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Issue Mts',
      selector: (row) => row.Issue_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Prcs Mts',
      selector: (row) => row.Prcs_Mts,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Job Card Date',
      selector: (row) => moment(row.job_card_date).format('DD/MM/YYYY'),
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = [
    'Customer_Name',
    'Customer_Sort',
    'LOT_NUM',
    'MU_ID',
    'MC_Code',
    'Machine_Name',
    'GrpName',
    'Start_Time',
    'end_time',
    'Packing',
    'Issue_Mts',
    'Prcs_Mts',
    'job_card_date',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Lot Position Report" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Lot Position Report</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Job Card / LotNum</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleJobCardChange(e)}
                    options={jobCardOptions}
                    id="jobCard"
                    name="jobCard"
                    placeholder="Select Job Card-Lot Number"
                    value={defaultJobCard}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>POC</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handlePOCChange(e)}
                    options={pocOptions}
                    id="poc"
                    name="poc"
                    placeholder="Select POC"
                    value={defaultPOC}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Stock</header>
            <CardBody>
              {showDownload && (
                <p
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LotPositionReport;
